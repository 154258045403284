.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.form-control {
  margin: 2rem 0 0.5rem 0;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}

form {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

label {
  color: #d5d5d5;
}

.input {
  background: #87898c;
  color: #282c34;
  border-color: transparent;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}
